import { Box, Stack } from "@mui/material";
import { HeaderMenuButton } from "./HeaderMenuButton";

export const Header = () => (
  <Stack
    direction="row"
    justifyContent="space-between"
    sx={{ marginTop: "1px !important", minWidth: "94%", padding: "3%" }}
  >
    <Box
      component="img"
      sx={{
        height: 30,
      }}
      src="/assets/stailogo.svg"
    />
    <HeaderMenuButton />
  </Stack>
);
