import { auth } from "@stai/common";
import { FC, PropsWithChildren, useEffect } from "react";
import { useIdToken } from "react-firebase-hooks/auth";
import { Outlet } from "react-router-dom";
import { useFirebaseAuthDialog } from "../../Firebase/hooks/useFirebaseAuthDialog";

export const WithAuthPopup: FC<PropsWithChildren> = () => {
  const [authUser] = useIdToken(auth);
  const { open, close } = useFirebaseAuthDialog();

  useEffect(() => {
    if (!authUser) {
      open();
    } else {
      close();
    }
  }, [authUser, close, open]);

  return <Outlet />;
};
