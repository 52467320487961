import { FIREBASE_ENV } from "..";

const EMULATOR_URL = "http://localhost:4000/storage/local.appspot.com";
const CONSOLE_URL = "https://console.firebase.google.com";

export function getStorageUrl(filePath: string) {
  if (FIREBASE_ENV.isEmulator) {
    return `${EMULATOR_URL}/${filePath}`;
  }

  const firebaseProjectName = FIREBASE_ENV.projectId;

  if (!firebaseProjectName) return;
  if (typeof window === "undefined") return;

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  const firebaseUser = window.localStorage.getItem("u");

  return `${CONSOLE_URL}${
    firebaseUser ? `/u/${String(firebaseUser)}` : ""
  }/project/${firebaseProjectName}/storage/stai-chat.appspot.com/files/~2F${filePath.replaceAll(
    "/",
    "~2F"
  )}`;
}
