import { auth } from "@stai/common";
import { FC } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useMainInit } from "../hooks/useMainInit";
import { MainRoutes } from "./MainRoutes";

export const MainContentView: FC = () => {
  const { error } = useMainInit();

  if (error) return <ErrorPage error={error} />;

  const [, isInitializingAuthState] = useAuthState(auth);

  if (isInitializingAuthState) {
    return <LoadingPage />;
  }

  return <MainRoutes />;
};
