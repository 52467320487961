import { Box, Button } from "@mui/material";

export function LoadMoreButton(props: { onClick: () => void }) {
  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginTop: 2,
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button
        onClick={() => props.onClick()}
        variant="contained"
        color="success"
        size="medium"
      >
        Load more
      </Button>
    </Box>
  );
}
