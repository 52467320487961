import { getCollectionRef, usePaginatedCollection } from "@stai/common";
import { FirebaseFilter, Stay, StayCollection } from "@stai/types";
import { orderBy, query, where } from "firebase/firestore";

export function useProjectStays(
  projectId: string | undefined,
  filters: FirebaseFilter<Stay>[] = []
) {
  return usePaginatedCollection(
    query(
      getCollectionRef(StayCollection(projectId)),
      ...filters.map((filter) => where(...filter)),
      orderBy("createdAt", "desc")
    ),
    Stay
  );
}
