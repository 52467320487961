import type { FirebaseFunctionDefinition } from "@stai/types";
import { HttpsCallableOptions } from "firebase/functions";
import { FIREBASE_ENV } from "..";
import { CallableError } from "./types/CallableError";

const SERVER_NAME = "app";

interface Args<RequestArgs, ResponseArgs>
  extends FirebaseFunctionDefinition<RequestArgs, ResponseArgs> {
  options?: HttpsCallableOptions;
}

export function createHttpFunction<RequestArgs, ResponseArgs>(
  args: Args<RequestArgs, ResponseArgs>
) {
  return async (requestArgs: RequestArgs): Promise<ResponseArgs> => {
    try {
      const params = args.getReq?.(requestArgs);

      const response = await fetch(
        `${FIREBASE_ENV.cloudFunctionsHost}/${SERVER_NAME}${args.path}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(params),
        }
      );
      return args.getRes?.(await response.json()) as ResponseArgs;
    } catch (error) {
      const callableError = new CallableError({
        path: args.path,
        error: error as Error,
      });

      console.error(callableError);

      throw callableError;
    }
  };
}
