import { auth, useMyUserRecord } from "@stai/common";
import { FC, useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useMyProjects } from "../../Project/hooks/useMyProjects";
import { useMainInit } from "../hooks/useMainInit";
import { setupSentryUser } from "../../Setup/setupSentry";

export const MainInitView: FC = () => {
  const [authState, isInitializingAuthState] = useAuthState(auth);
  const myUser = useMyUserRecord();
  const isInitializingMyProjects = useMyProjects(
    (state) => state.isInitializing
  );
  const userId = authState?.uid;

  // Dependency: userId
  useEffect(() => {
    if (userId) {
      useMyUserRecord.getState().subscribe(userId);
    }

    return () => {
      useMyUserRecord.getState().unsubscribe?.();
    };
  }, [userId]);

  useEffect(() => {
    if(authState) {
      setupSentryUser(authState);
    }
  }, [authState]);

  // Dependency: managedProjectIds
  useEffect(() => {
    if (myUser?.user?.managedProjectIds) {
      useMyProjects.getState().subscribe(myUser?.user?.managedProjectIds);
    }

    return () => {
      useMyProjects.getState().unsubscribe?.();
    };
  }, [myUser?.user?.managedProjectIds]);

  const isInitializing =
    isInitializingAuthState ||
    (userId ? myUser.isInitializing : false) ||
    isInitializingMyProjects;

  useEffect(() => {
    useMainInit.setState({ isInitializing });
  }, [isInitializing]);

  return null;
};
