import { auth } from "@stai/common";
import { useIdToken } from "react-firebase-hooks/auth";

export const useIsAnonymous = () => {
  const [authUser] = useIdToken(auth);

  if (!authUser) return true;

  return authUser.isAnonymous;
};
