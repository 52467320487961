import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import PersonIcon from "@mui/icons-material/Person";
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { auth, useMyUserRecord } from "@stai/common";
import { Chat, Project } from "@stai/types";
import { FC, useCallback, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { ENVIRONMENT } from "../consts/ENVIRONMENT";
import { handleError } from "../helpers/handleError";

interface Props {
  project?: Project;
  chat?: Chat;
}

export const HeaderMenuButton: FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [authState] = useAuthState(auth);
  const user = useMyUserRecord((s) => s.user);
  const isAdmin = user?.role;
  const navigate = useNavigate();

  const logout = useCallback(async () => {
    try {
      await auth.signOut();
      setAnchorEl(null);
    } catch (error) {
      handleError(error);
    }
  }, []);

  const redirectToAdmin = () => {
    navigate(ENVIRONMENT.consoleDomain);
  };
  return (
    <>
      <IconButton
        size="large"
        onClick={(event) => setAnchorEl(event.currentTarget)}
      >
        <MenuIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <MenuItem component="div" disabled={true}>
          <ListItemText>{authState?.email}</ListItemText>
        </MenuItem>
        {isAdmin && (
          <MenuItem onClick={redirectToAdmin}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText>Admin</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={logout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText>
            <Typography>Logout</Typography>
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
};
