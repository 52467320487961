import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Stack, Typography } from "@mui/material";
import { createCallable } from "@stai/common";
import { AddStayDefinition, UpdateStayDefinition } from "@stai/types";
import { DateTime } from "luxon";
import { useTranslation } from "next-i18next";
import { FC, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { Header } from "../../Common/views/Header";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { LoadingView } from "../../Common/views/LoadingView";
import { ControlledDatePicker } from "../../Form/views/ControlledDatePicker";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useProject } from "../../Project/hooks/useProject";
import { useStay } from "../hooks/useStay";

const addStayCallable = createCallable(AddStayDefinition);
const updateStayCallable = createCallable(UpdateStayDefinition);

const luxonDateTime = z.custom((val: any) => {
  return val instanceof DateTime;
});

const FormValues = z.object({
  name: z.string(),
  email: z.string(),
  checkinDate: luxonDateTime,
  checkoutDate: luxonDateTime.optional(),
  roomNumber: z.string().optional(),
});

type FormValues = z.infer<typeof FormValues>;

type GuestFormPageParams = {
  projectId: string;
  stayId: string;
};

export const GuestFormPage: FC = () => {
  const params = useParams<GuestFormPageParams>();
  const { projectId, stayId } = params;

  const [project, isLoadingProject] = useProject(projectId);
  const [stay, isLoadingStay] = useStay(projectId, stayId);
  const [isCreating, setIsCreating] = useState(false);
  const { control, handleSubmit, reset } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { name: "", checkinDate: DateTime.now() },
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (stayId && stay) {
      reset({
        name: stay.name,
        checkinDate:
          stay.checkinDate && DateTime.fromJSDate(stay.checkinDate.toDate()),
        checkoutDate:
          stay.checkoutDate && DateTime.fromJSDate(stay.checkoutDate.toDate()),
        roomNumber: stay.roomNumber,
      });
    }
  }, [stayId, stay]);

  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    const checkIn = (values.checkinDate as DateTime).toJSDate();
    const checkOut = (values.checkoutDate as DateTime).toJSDate();
    setIsCreating(true);
    let updatedStayId = stayId;
    console.log("values", values);
    if (stayId) {
      await updateStayCallable({
        projectId: projectId!,
        stayId: stayId,
        name: values.name,
        ...(values.email ? { email: values.email } : {}),
        ...(values.roomNumber ? { roomNumber: values.roomNumber } : {}),
        checkinDate: checkIn.toISOString(),
        checkoutDate: checkOut.toISOString(),
        guestId: stayId,
      }).catch((e: any) => handleError(e));
    } else {
      await addStayCallable({
        projectId: projectId!,
        name: values.name,
        ...(values.email ? { email: values.email } : {}),
        ...(values.roomNumber ? { roomNumber: values.roomNumber } : {}),
        checkinDate: checkIn.toISOString(),
        checkoutDate: checkOut.toISOString(),
      })
        .then(({ stayId }: { stayId: string }) => (updatedStayId = stayId))
        .catch((e: any) => handleError(e));
    }
    navigate(`/projects/${projectId}/stays/${updatedStayId}/qr`);

    setIsCreating(false);
  };

  if (isLoadingStay || isLoadingProject) return <LoadingPage />;
  if (!project) return <ErrorPage error={t("project_not_found")} />;
  if (stayId && !stay) return <ErrorPage error={t("stay_not_found")} />;

  return (
    <>
      <Header />
      <Stack justifyContent="center" flex={1} spacing={4}>
        <Typography
          variant="h2"
          textAlign="center"
          sx={{
            fontWeight: "700",
            textTransform: "uppercase",
          }}
        >
          {stayId ? t("update_guest_details") : t("add_guest")}
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            justifyContent="center"
            flexWrap="wrap"
            gap={3}
            sx={{ padding: "10px" }}
          >
            <ControlledTextField
              required
              label={t("name")}
              control={control}
              name={"name"}
            />
            <ControlledTextField
              label={t("email")}
              control={control}
              name={"email"}
            />
            <ControlledTextField
              label={t("room_number")}
              control={control}
              name={"roomNumber"}
            />
            <ControlledDatePicker
              required
              label={t("check_in_date")}
              control={control}
              name={"checkinDate"}
            />
            <ControlledDatePicker
              required
              label={t("check_out_date")}
              control={control}
              name={"checkoutDate"}
            />
            <Button
              variant="contained"
              color="success"
              size="large"
              type={"submit"}
              sx={{
                marginTop: "10px",
              }}
            >
              {isCreating ? (
                <LoadingView />
              ) : stayId ? (
                t("save")
              ) : (
                t("add_guest")
              )}
            </Button>
            <Button
              sx={{
                mt: "45px",
              }}
              variant="outlined"
              size="medium"
              color="error"
              component={Link}
              to={`/projects/${projectId}`}
            >
              {t("back")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </>
  );
};
