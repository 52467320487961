import WebIcon from "@mui/icons-material/Web";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import { useTranslation } from "next-i18next";
import React, { FC } from "react";
import { Link, useParams } from "react-router-dom";
import { ENVIRONMENT } from "../../Common/consts/ENVIRONMENT";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingView } from "../../Common/views/LoadingView";
import { useProject } from "../../Project/hooks/useProject";
import { generateStartMessage } from "@stai/types";

export const MessengersPage: FC = () => {
  const { projectId, stayId } = useParams<{
    projectId: string;
    stayId: string;
  }>();
  const [open, setOpen] = React.useState(true);
  const [project, loading, error] = useProject(projectId);
  const { t } = useTranslation();

  if (loading) return <LoadingView />;
  if (!projectId) return <ErrorPage error="Project id is required" />;
  if (!stayId) return <ErrorPage error="Stay id is required" />;
  if (error || !project) return <ErrorPage error="Project not found" />;

  const handleClose = () => {
    setOpen(false);
  };

  const browserLanguage = navigator.language ?? "en-GB";

  const startMessage = generateStartMessage({
    stayId,
    projectId,
    language: browserLanguage,
  })
   Buffer.from(`${stayId}:${browserLanguage}`)
    .toString("base64")
    .replace("=", ""); // Telegram does not support = and other symbols like : in commands

  const whatsappUrl = `https://wa.me/${project.whatsappPhoneNumber || process.env.NEXT_PUBLIC_WHATSAPP_SHARED_PHONE_NUMBER}?text=/start ${startMessage}`;
  const telegramUrl = project.telegramBotName
    ? `https://t.me/${project.telegramBotName}?start=${startMessage}`
    : undefined;
  const webUrl = `${ENVIRONMENT.messengerDomain}/projects/${projectId}/stays/${stayId}`;

  return (
    <Stack
      justifyContent="center"
      flex={1}
      spacing={4}
      sx={{ padding: "20px" }}
    >
      <Box>
        <Typography variant="h1" textAlign="center">
          {t("welcome")}
          <br />
          {project?.name}
        </Typography>
      </Box>
      <Stack
        justifyContent="center"
        flex={1}
        spacing={4}
        sx={{ padding: "20px" }}
      >
        <Typography variant="h2" textAlign="center">
          {t("guest_choose_messenger")}
        </Typography>
        <Stack direction="row" spacing={4} justifyContent="center">
          {whatsappUrl && (
            <Card
              sx={{
                backgroundColor: "white",
                color: "black",
                width: "40%",
                borderRadius: "20px",
              }}
            >
              <CardActionArea
                component={Link}
                to={whatsappUrl}
                sx={{ borderRadius: "20px" }}
              >
                <CardContent>
                  <Stack alignItems="center" spacing={2}>
                    <WhatsAppIcon color="success" style={{ fontSize: 60 }} />
                    <Typography variant="h6">WhatsApp</Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          {telegramUrl && (
            <Card
              sx={{
                backgroundColor: "white",
                color: "black",
                width: "40%",
                borderRadius: "20px",
              }}
            >
              <CardActionArea component={Link} to={telegramUrl}>
                <CardContent>
                  <Stack alignItems="center" spacing={2}>
                    <TelegramIcon style={{ fontSize: 60 }} color="info" />
                    <Typography variant="h6">Telegram</Typography>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          )}
          <Card
            sx={{
              backgroundColor: "white",
              color: "black",
              width: "40%",
              borderRadius: "20px",
            }}
          >
            <CardActionArea component={Link} to={webUrl}>
              <CardContent>
                <Stack alignItems="center" spacing={2}>
                  <WebIcon style={{ fontSize: 60 }} color="info" />
                  <Typography variant="h6">Web</Typography>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
        </Stack>
      </Stack>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ fontSize: "30px" }}>
          {t("gdpr_consent_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>{t("gdpr_consent_message")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            variant="contained"
            color="success"
            size="large"
            sx={{ width: "100%" }}
          >
            {t("agree")}
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
