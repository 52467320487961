import { User, UsersCollection } from "@stai/types";
import { onSnapshot } from "firebase/firestore";
import { create } from "zustand";
import { getDataFromDocumentSnapshot } from "./getDataFromDocumentSnapshot";
import { getDocRef } from "./getDocRef";

interface State {
  user?: User;
  isInitializing: boolean;
  error: Error | undefined;
  subscribe: (userId: string) => void;
  unsubscribe?: () => void;
}

export const useMyUserRecord = create<State>((set) => ({
  isInitializing: true,
  error: undefined,
  subscribe: (userId) => {
    const unsubscribeFirestore = onSnapshot(
      getDocRef(UsersCollection, userId),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const user = getDataFromDocumentSnapshot(User, snapshot);

        set({
          user,
          error: undefined,
          ...(user && { isInitializing: false }),
        });
      },
      (error) => {
        set({ user: undefined, isInitializing: false, error });
      }
    );

    set({
      unsubscribe: () => {
        set({ user: undefined, isInitializing: true, error: undefined });
        unsubscribeFirestore();
      },
    });
  },
}));
