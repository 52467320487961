import { Box, Stack } from "@mui/material";
import { auth } from "@stai/common";
import { FC } from "react";
import { useNavigate } from "react-router-dom";

export const HomePage: FC = () => {
  const navigate = useNavigate();
  if (auth.currentUser) {
    navigate("/projects");
  } else {
    return (
      <Stack justifyContent="start" flex={1} spacing={4} alignContent="center">
        <Box
          component="img"
          sx={{
            height: 30,
          }}
          src="/assets/stailogo.svg"
        />
      </Stack>
    );
  }
};
