import { FC } from "react";
import { Route, Routes } from "react-router-dom";
import { FirebaseAuthDialog } from "../../Firebase/views/FirebaseAuthDialog";
import { MessengersPage } from "../../Guest/views/MessengersPage";
import { GuestFormPage } from "../../Guest/views/StayFormPage";
import { StayQRCode } from "../../Guest/views/StayQRCode";
import { HomePage } from "../../Home/views/HomePage";
import { AcceptInvitationPage } from "../../Invitation/views/AcceptInvitationPage";
import { ProjectPage } from "../../Project/views/ProjectPage";
import { ProjectsPage } from "../../Project/views/ProjectsPage";
import { MainLayout } from "./MainLayout";
import { WithAuthPopup } from "./WithAuthPopup";
import { MyProjectGuard } from "./MyProjectsGuard";

export const MainRoutes: FC = () => {
  return (
    <>
      <Routes>
        <Route element={<MainLayout />}>
          {/* public routes */}
          <Route
            path="/projects/:projectId/stays/:stayId/messenger"
            element={<MessengersPage />}
          />
          <Route
            path="/projects/:projectId/invitations/:invitationId"
            element={<AcceptInvitationPage />}
          />
          {/* logged in routes */}
          <Route element={<WithAuthPopup />}>
            <Route index element={<HomePage />} />
            <Route path="/projects" element={<ProjectsPage />} />
            <Route path="/projects/:projectId" element={<MyProjectGuard />}>
              <Route index element={<ProjectPage />} />
              <Route
                path="add-stay"
                element={<GuestFormPage />}
              />
              <Route
                path="stays/:stayId"
                element={<GuestFormPage />}
              />
              <Route
                path="stays/:stayId/qr"
                element={<StayQRCode />}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
      <FirebaseAuthDialog />
    </>
  );
};
