import { DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC, useEffect } from "react";
import { DrawerDialog } from "../../Common/views/DrawerDialog";
import { useFirebaseAuthDialog } from "../hooks/useFirebaseAuthDialog";
import { FirebaseAuthEmailForm } from "./FirebaseAuthEmailForm";
import { FirebaseAuthView } from "./FirebaseAuthView";

export const FirebaseAuthDialog: FC = () => {
  const { isOpen, close } = useFirebaseAuthDialog();
  const { t } = useTranslation();

  useEffect(() => {
    if (!isOpen) return;
  }, [isOpen]);

  return (
    <DrawerDialog open={isOpen}>
      <DialogContent>
        <Stack spacing={2}>
          <Typography mb={1} fontSize={12} textAlign="center" color="white">
            {t("please_sign_in")}
          </Typography>
          <FirebaseAuthEmailForm />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack spacing={2} pb={3} sx={{ width: "100%" }}>
          <Typography mb={1} fontSize={12} textAlign="center" color="white">
            {t("or")}
          </Typography>
          <FirebaseAuthView />
        </Stack>
      </DialogActions>
    </DrawerDialog>
  );
};
