import { DocumentData, DocumentSnapshot } from "firebase/firestore";
import { ZodType, output } from "zod";

export function getDataFromDocumentSnapshot<T extends ZodType<any, any, any>>(
  zodSchema: T,
  doc?: DocumentSnapshot<DocumentData>
): output<T> | undefined {
  if (doc?.exists()) {
    const data = doc.data();

    const result = zodSchema.safeParse(data);
    if (result.success) {
      return result.data;
    } else {
      console.error("corupt data", { error: result.error, data });
    }
  }
}
