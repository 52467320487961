interface Args {
  path: string;
  error: Error;
}

export class CallableError extends Error {
  readonly path: string;

  constructor(args: Args) {
    super(`${args.path} ${args.error.message}`, { cause: args.error });
    this.path = args.path;
  }
}
