import { Alert, Stack } from "@mui/material";
import { FC } from "react";

interface Props {
  errorMessage: string;
}

export const ErrorView: FC<Props> = (props) => {
  const { errorMessage } = props;

  return (
    <Stack p={2}>
      <Alert severity="error">{errorMessage}</Alert>
    </Stack>
  );
};
