import {
  Dialog,
  Drawer,
  SxProps,
  Theme,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { FC, PropsWithChildren } from "react";

interface Props extends PropsWithChildren {
  open: boolean;
  onClose?: () => void;
  sx?: SxProps;
  PaperPropsSx?: SxProps;
}

export const DrawerDialog: FC<Props> = (props) => {
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down("sm"));
  const { breakpoints, palette, spacing } = useTheme();

  if (isSmall) {
    return (
      <Drawer
        open={props.open}
        anchor="bottom"
        onClose={props.onClose}
        sx={props.sx}
        PaperProps={{
          sx: {
            background: palette.background.gradient,
            maxHeight: `calc(100% - ${spacing(4)})`,
            margin: spacing(0),
            ...props.PaperPropsSx,
          },
        }}
      >
        {props.children}
      </Drawer>
    );
  }

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      sx={props.sx}
      PaperProps={{
        sx: {
          background: palette.background.gradient,
          borderRadius: 2,
          minWidth: `calc(${breakpoints.values.sm}px - ${spacing(4)})`,
          ...props.PaperPropsSx,
        },
      }}
    >
      {props.children}
    </Dialog>
  );
};
