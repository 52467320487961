import { Button, darken, lighten, Stack, styled } from "@mui/material";
import { auth, authProviderFactory, SignInMethod } from "@stai/common";
import { getRedirectResult, signInWithPopup, signInWithRedirect } from "firebase/auth";
import { FC, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { handleError } from "../../Common/helpers/handleError";

interface Props {
  size?: "small";
}

export const FirebaseAuthView: FC<Props> = (props) => {
  const { size } = props;
  const navigate = useNavigate();

  const submit = useCallback(async (signInMethod: SignInMethod) => {
    try {
      const provider = authProviderFactory(signInMethod);
      signInWithPopup(auth, provider)
        .catch(async function (e) {
          if (e.code == "auth/popup-blocked") {
            console.error("Sign in with popup failed. Trying with redirect");
            return await signInWithRedirect(auth, provider);
          } else {
            console.error("Sign in error", e);
            handleError(e);
          }
        })
        .then((result) => {
          console.log("Sign ini result", result);
        });
    } catch (error) {
      handleError(error);
    }
  }, []);

  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (!result) return;
        if (result.operationType === "signIn") {
          navigate("/projects");
        }
      })
      .catch(handleError);
  }, [navigate]);

  return (
    <>
      <Stack spacing={size === "small" ? 1 : 2}>
        {/* <FirebaseAuthEmailForm size={size} /> */}
        {/* {size !== "small" && <Stack textAlign="center">OR</Stack>} */}
        <SGoogleButton
          variant="contained"
          size="large"
          startIcon={
            <img
              src="/assets/auth-google.svg"
              alt="Google"
              width={22}
              height={22}
            />
          }
          onClick={() => {
            void submit("google.com");
          }}
        >
          Sign in with Google
        </SGoogleButton>
      </Stack>
    </>
  );
};

const SGoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(theme.palette.grey[400]),
  backgroundColor: theme.palette.grey[400],
  "&:hover": {
    backgroundColor: darken(theme.palette.grey[400], 0.2),
  },
  ".MuiButton-startIcon": {
    marginRight: theme.spacing(2),
  },
}));

const SAppleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.common.white,
  backgroundColor: theme.palette.common.black,
  "&:hover": {
    backgroundColor: lighten(theme.palette.common.black, 0.07),
  },
  ".MuiButton-startIcon": {
    marginRight: theme.spacing(2),
  },
}));
