import { Analytics, getAnalytics, isSupported } from "firebase/analytics";
import { getApps, initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectStorageEmulator, getStorage } from "firebase/storage";

export const FIREBASE_ENV = {
  region: process.env.NEXT_PUBLIC_FIREBASE_REGION || "",
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY || "",
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID || "",
  measurementId: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID || "",
  isEmulator: process.env.NEXT_PUBLIC_FIREBASE_IS_EMULATOR === "true",
  cloudFunctionsHost: process.env.NEXT_PUBLIC_CLOUD_FUNCTION_HOST,
  nodeEnv: process.env.NODE_ENV,
};

const { isEmulator } = FIREBASE_ENV;
const region = isEmulator ? "us-central1" : FIREBASE_ENV.region;

const APPNAME = "stai";
export const app =
  getApps().find((app) => app.name === APPNAME) ||
  initializeApp(
    {
      ...FIREBASE_ENV,
    },
    APPNAME
  );

export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, region);
export const storage = getStorage(app);

export let analytics: Analytics | undefined = undefined;

void (async () => {
  if (FIREBASE_ENV.nodeEnv !== "production") return;
  const isAnalyticsSupported = await isSupported();
  if (!isAnalyticsSupported) return;
  analytics = getAnalytics(app);
})();

/**
 * To use this you have to set NEXT_PUBLIC_FIREBASE_IS_EMULATOR=true in .env
 */
if (isEmulator) {
  const host = process.env.NEXT_PUBLIC_FIREBASE_EMULATOR_HOST || "localhost";

  connectFunctionsEmulator(functions, host, 5001);
  connectFirestoreEmulator(db, host, 8080);
  connectAuthEmulator(auth, `http://${host}:9099`, { disableWarnings: true });
  connectStorageEmulator(storage, host, 9199);
}

export * from "./auth";
export * from "./firestore";
export * from "./functions";
export * from "./storage";
