import { FC, PropsWithChildren } from "react";
import { Outlet } from "react-router-dom";
import { MainContainer } from "./MainContainer";

export const MainLayout: FC<PropsWithChildren> = () => {
  return (
    <MainContainer sx={{ minHeight: "100%" }}>
      <Outlet />
    </MainContainer>
  );
};
