import { Button, Stack, styled, Typography } from "@mui/material";
import { useMyUserRecord } from "@stai/common";
import { useTranslation } from "next-i18next";
import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Header } from "../../Common/views/Header";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useMyProjects } from "../hooks/useMyProjects";

export const ProjectsPage: FC = () => {
  const user = useMyUserRecord((state) => state.user);
  const { projects, isInitializing, subscribe } = useMyProjects();
  const navigate = useNavigate();

  const { t } = useTranslation();

  useEffect(() => {
    if (!user || !user.managedProjectIds) return;
    return subscribe(user.managedProjectIds);
  }, [user, subscribe]);

  if (isInitializing) return <LoadingPage />;
  if (projects.length == 1) navigate(`/projects/${projects[0].id}`);

  return (
    <>
      <Header />
      <Stack justifyContent="center" flex={1} spacing={4}>
        {projects.length === 0 && (
          <Typography variant="h1" textAlign="center">
            {t("no_access_to_projects")}
          </Typography>
        )}
        {projects.length > 0 && (
          <>
            <Typography variant="h1" textAlign="center">
              {t("select_project")}
            </Typography>
            <Stack
              direction="row"
              justifyContent="center"
              flexWrap="wrap"
              gap={1}
            >
              {projects.map((project) => (
                <SButton
                  key={project.id}
                  variant="outlined"
                  size="large"
                  onClick={() => navigate(`/projects/${project.id}`)}
                >
                  {project.name}
                </SButton>
              ))}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

const SButton = styled(Button)(({ theme }) => ({
  minWidth: 220,
  color: theme.palette.common.white,
  borderColor: theme.palette.common.white,
}));
