import { zodResolver } from "@hookform/resolvers/zod";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { FC } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { LoadingView } from "../../Common/views/LoadingView";
import { ControlledTextField } from "../../Form/views/ControlledTextField";
import { useProject } from "../../Project/hooks/useProject";
import { createHttpFunction } from "@stai/common";
import { AcceptInvitationDefinition } from "@stai/types";

const acceptInvitationCallable = createHttpFunction(AcceptInvitationDefinition);

const FormValues = z.object({
  email: z.string(),
  password: z.string().min(8),
});
type FormValues = z.infer<typeof FormValues>;

export const AcceptInvitationPage: FC = () => {
  const { invitationId, projectId } = useParams<{
    projectId: string;
    invitationId: string;
  }>();

  const { t } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailParam = queryParams.get("email") || null;

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: {
      email: emailParam || "",
      password: "",
    },
  });

  const navigate = useNavigate();

  const onSubmit = async (values: FormValues) => {
    if (invitationId && projectId) {
      await acceptInvitationCallable({
        projectId,
        invitationId,
        email: values.email,
        password: values.password,
      })
        .then(({ error }) => {
          if (error) {
            toast.error(error);
          } else {
            toast.success("invitation accepted");
            navigate(`/projects/${projectId}`);
          }
        })
        .catch((error) => handleError(error));
    }
  };

  if (!invitationId) return <ErrorPage error="invitationId is required" />;

  const [project, loadingProject, errorProject] = useProject(projectId);
  if (loadingProject) {
    return <LoadingView />;
  }
  if (errorProject) return <ErrorPage error="Project not found" />;

  return (
    <Stack
      justifyContent="center"
      flex={1}
      spacing={4}
      sx={{ padding: "20px" }}
    >
      <Box>
        <Typography variant="h1" textAlign="center">
          {t("welcome")}
          <br />
          {project?.name}
        </Typography>
      </Box>
      <Stack
        justifyContent="center"
        flex={1}
        spacing={4}
        sx={{ padding: "20px" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack
            direction="column"
            justifyContent="center"
            flexWrap="wrap"
            gap={3}
            sx={{ padding: "10px" }}
          >
            <ControlledTextField
              required
              label={t("email")}
              control={control}
              name={"email"}
              disabled={!!emailParam}
            />
            <ControlledTextField
              required
              label={t("password")}
              control={control}
              name={"password"}
            />
            <Button
              variant="contained"
              color="success"
              size="large"
              type={"submit"}
              disabled={!formState.isValid}
              sx={{
                marginTop: "10px",
              }}
            >
              {t("accept_invitation")}
            </Button>
          </Stack>
        </form>
      </Stack>
    </Stack>
  );
};
