import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

export type SignInMethod = "google.com" | "apple.com";

export function authProviderFactory(signInMethod: SignInMethod) {
  switch (signInMethod) {
    case "apple.com":
      return new OAuthProvider("apple.com");
    case "google.com":
    default:
      return new GoogleAuthProvider();
  }
}
