import {
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Stay } from "@stai/types";
import { DateTime } from "luxon";
import { useTranslation } from "next-i18next";
import React, { FC, useState } from "react";
import { Link, useParams } from "react-router-dom";
import AddGuestButton from "../../Common/views/AddGuestButton";
import { ErrorPage } from "../../Common/views/ErrorPage";
import { Header } from "../../Common/views/Header";
import { LoadingPage } from "../../Common/views/LoadingPage";
import { useProject } from "../hooks/useProject";
import { useProjectStays } from "../hooks/useProjectStays";
import { LoadMoreButton } from "./ProjectPage/LoadMoreButton";

function stayDatesStr(stay: Stay) {
  return stay.checkinDate && stay.checkoutDate
    ? `${DateTime.fromJSDate(stay.checkinDate.toDate()).toFormat(
        "MM.dd.yyyy"
      )} - ${DateTime.fromJSDate(stay.checkoutDate.toDate()).toFormat(
        "MM.dd.yyyy"
      )}`
    : "";
}

export const ProjectPage: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const [project, isLoadingProject] = useProject(projectId);

  const [searchQuery, setSearchQuery] = useState("");

  const [stays, , , staysController] = useProjectStays(projectId, [
    ["name", ">=", searchQuery],
  ]);

  const { t } = useTranslation();

  if (isLoadingProject) return <LoadingPage />;
  if (!projectId || !project)
    return <ErrorPage error={t("project_not_found")} />;

  return (
    <Stack justifyContent="start" flex={1} spacing={4}>
      <Stack direction="row" justifyContent="center" flexWrap="wrap" gap={1}>
        <Header />
        <TextField
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          label={"Search"}
          sx={{
            width: "100%",
          }}
        />
        <List
          sx={{
            minWidth: "100%",
            maxWidth: "100%",
          }}
        >
          {stays.map((stay) => (
            <ListItem
              key={stay.id}
              sx={{
                backgroundColor: "white",
                color: "#141414",
                marginTop: "10px",
                borderRadius: "8px",
              }}
            >
              <ListItemButton
                component={Link}
                key={stay.id}
                to={`/projects/${projectId!}/stays/${stay.id}/qr`}
              >
                <ListItemText
                  secondary={
                    <React.Fragment>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body1"
                      >
                        {t("room") + ": "}
                        <Typography sx={{ color: "#141414" }} component="span">
                          {stay.roomNumber}
                        </Typography>
                      </Typography>{" "}
                      <br />
                      {stayDatesStr(stay)}
                    </React.Fragment>
                  }
                  primary={
                    <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                      {stay.name}
                    </Typography>
                  }
                />
              </ListItemButton>
              <Button
                variant="outlined"
                component={Link}
                to={`/projects/${projectId}/stays/${stay.id}`}
                size="small"
              >
                {t("edit")}
              </Button>
            </ListItem>
          ))}
          {staysController.hasMore ? (
            <LoadMoreButton onClick={staysController.loadMore} />
          ) : null}
        </List>
      </Stack>
      <AddGuestButton to="add-stay" text={t("add_guest")}></AddGuestButton>
    </Stack>
  );
};
