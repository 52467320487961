import { FC } from "react";
import { ErrorView } from "./ErrorView";

interface Props {
  error: Error | string;
}

export const ErrorPage: FC<Props> = (props) => {
  const { error } = props;

  const errorMessage = error instanceof Error ? error.message : error;

  return <ErrorView errorMessage={errorMessage} />;
};
