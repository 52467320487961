import {
  DocumentChange,
  DocumentData,
  FirestoreError,
  QueryDocumentSnapshot,
} from "firebase/firestore";
import { Action, ActionType } from "./actions";

interface ReducerState {
  elements: QueryDocumentSnapshot[];
  isLoading: boolean;
  isLoadingMore: boolean;
  lastElement: QueryDocumentSnapshot | null;
  hasMoreElements: boolean;
  after: QueryDocumentSnapshot | null;
  error: FirestoreError | null;
}

export const initialState: ReducerState = {
  elements: [],
  isLoading: false,
  isLoadingMore: false,
  lastElement: null,
  hasMoreElements: false,
  after: null,
  error: null,
};

export const reducer = (state: ReducerState, action: Action): ReducerState => {
  switch (action.type) {
    case ActionType.LOADING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionType.LOADED: {
      const { snapshot, pageSize } = action.payload;
      const isInitialLoad = !state.elements.length;
      const elements = [...state.elements];
      const changes = snapshot.docChanges();

      changes
        .sort((a, b) => a.newIndex - b.newIndex)
        .forEach((change: DocumentChange) => {
          if (change.type === "added") {
            const method =
              isInitialLoad || state.isLoadingMore ? "append" : "prepend";

            addElement(change.doc, elements, method);
          }
          if (change.type === "modified") {
            updateElement(change.doc, elements);
          }
        });

      const lastElement = elements[elements.length - 1];

      const isLoadingChunk = isInitialLoad || state.isLoadingMore;
      const hasChanges = !!changes.length;

      // TODO: find a better option to determine, whether there are more elements available.
      const hasMoreElements =
        (isLoadingChunk && hasChanges && changes.length === pageSize) || // loading a full chunk
        (state.hasMoreElements && hasChanges); // having more elements and loading a partial chunk

      return {
        ...state,
        isLoading: false,
        isLoadingMore: false,
        elements,
        lastElement,
        hasMoreElements,
      };
    }
    case ActionType.LOAD_MORE:
      return {
        ...state,
        after: state.lastElement,
        isLoadingMore: true,
      };
    case ActionType.ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
        isLoadingMore: false,
      };
    case ActionType.RESET:
      return initialState;
    default:
      return state;
  }
};

function findIndexOfDocument(
  doc: QueryDocumentSnapshot,
  items: DocumentData[]
): number {
  return items.findIndex((item) => {
    return item.id === doc.id;
  });
}

function updateElement(
  doc: QueryDocumentSnapshot,
  items: DocumentData[]
): void {
  const i = findIndexOfDocument(doc, items);
  items[i] = doc;
}

function addElement(
  doc: QueryDocumentSnapshot,
  items: DocumentData[],
  method: "append" | "prepend"
): void {
  const i = findIndexOfDocument(doc, items);
  if (i >= 0) return;

  if (method === "append") {
    items.push(doc);
  }

  if (method === "prepend") {
    items.unshift(doc);
  }
}
