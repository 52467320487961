import { CircularProgress, Stack } from "@mui/material";
import { FC } from "react";

export const LoadingView: FC = () => {
  return (
    <Stack
      flex={1}
      alignItems="center"
      justifyContent="center"
      sx={{ height: "100%" }}
    >
      <CircularProgress color="info" />
    </Stack>
  );
};
