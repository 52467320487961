import { DocumentData, QuerySnapshot } from "firebase/firestore";
import { ZodType, output } from "zod";

export function getDataFromQuerySnapshot<T extends ZodType<any, any, any>>(
  zodSchema: T,
  snapshot?: QuerySnapshot<DocumentData>
): output<T>[] {
  if (!snapshot) return [];

  return snapshot.docs.reduce<T[]>((result, doc) => {
    const data = doc.data();

    const parsed = zodSchema.safeParse(data);

    if (parsed.success) {
      result.push(parsed.data);
    } else {
      // TODO handle error
      console.error("corupt data", {error: parsed.error, data});
    }

    return result;
  }, []);
}
