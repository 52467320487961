import { zodResolver } from "@hookform/resolvers/zod";
import DoorFront from "@mui/icons-material/DoorFront";
import { Button, Stack } from "@mui/material";
import { auth } from "@stai/common";
import { FirebaseError } from "firebase/app";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useTranslation } from "next-i18next";
import { FC, useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { handleError } from "../../Common/helpers/handleError";
import { LoadingView } from "../../Common/views/LoadingView";
import { ControlledTextField } from "../../Form/views/ControlledTextField";

/**
 * Credits: https://github.com/colinhacks/zod/issues/653#issuecomment-930293063
 */
const emailSchema = z.preprocess(
  (u: unknown) => (typeof u === "string" ? u.trim().toLowerCase() : u),
  z.string().email()
);

const FormValues = z.object({
  email: emailSchema,
  password: z.string(),
});

type FormValues = z.infer<typeof FormValues>;

interface Props {
  size?: "small";
}

export const FirebaseAuthEmailForm: FC<Props> = (props) => {
  const { size } = props;
  const [isSignIn, setIsSignIn] = useState(false);
  const { pathname } = useLocation();

  const { t } = useTranslation();

  const { control, handleSubmit, formState } = useForm<FormValues>({
    resolver: zodResolver(FormValues),
    defaultValues: { email: "" },
  });

  const submit = useCallback(
    async (formValues: FormValues) => {
      const { email, password } = formValues;
      try {
        await signInWithEmailAndPassword(auth, email, password);

        setIsSignIn(true);
      } catch (error) {
        if (error instanceof FirebaseError) {
          switch (error.code) {
            case "auth/wrong-password":
            case "auth/invalid-credential":
              toast.error(`Password for the user ${email} does not match`);
              break;
            case "auth/user-not-found":
              toast.error(`User ${email} not found`);
              break;
            default:
              handleError(error);
          }
        } else {
          handleError(error);
        }
      } finally {
        setIsSignIn(false);
      }
    },
    [pathname]
  );

  const isLoading = formState.isSubmitting;

  return (
    <form onSubmit={handleSubmit(submit)}>
      <Stack spacing={size === "small" ? 1 : 2}>
        {isSignIn && <LoadingView />}
        {!isSignIn && (
          <>
            <ControlledTextField
              name="email"
              control={control}
              disabled={isLoading}
              placeholder={t("email")}
              fullWidth
            />
            <ControlledTextField
              name="password"
              type="password"
              control={control}
              disabled={isLoading}
              placeholder={t("password")}
              fullWidth
            />
            <Button
              size="large"
              type="submit"
              variant="contained"
              color="primary"
              disabled={isLoading}
              startIcon={<DoorFront />}
            >
              {t("sign_in")}
            </Button>
          </>
        )}
      </Stack>
    </form>
  );
};
