import { User } from "firebase/auth";
import { create } from "zustand";

interface MainInitState {
  error?: Error;
  isInitializing: boolean;
  isNewUser?: boolean;
  onAuthState: (
    authState: User | null | undefined,
    isInitializingAuthState: boolean
  ) => void;
}

export const useMainInit = create<MainInitState>((set, get) => ({
  error: undefined,
  isInitializing: true,
  onAuthState: (authState) => {
    if (get().isNewUser !== undefined) return;

    // We mutate the state directly here, because we don't want to trigger a re-render
    get().isNewUser = !authState;
  },
}));
