import { DocumentData, Query, QuerySnapshot } from "firebase/firestore";
import { useMemo } from "react";
import { useCollection } from "react-firebase-hooks/firestore";
import { ZodType, output } from "zod";
import { getDataFromQuerySnapshot } from "./getDataFromQuerySnapshot";

export function useValidCollectionData<T extends ZodType<any, any, any>>(
  query: Query<DocumentData> | null,
  zodSchema: T,
  compareFn?: (a: T, b: T) => number
):
  | [docs: output<T>[], isLoading: true, error: undefined, snapshot: undefined]
  | [
      docs: output<T>[],
      isLoading: false,
      error: undefined,
      snapshot: QuerySnapshot<DocumentData> | undefined
    ]
  | [docs: output<T>[], isLoading: false, error: Error, snapshot: undefined] {
  const [snapshot, isLoading, error] = useCollection(query);

  const docs = useMemo(() => {
    const items = getDataFromQuerySnapshot(zodSchema, snapshot);
    return compareFn ? items.sort(compareFn) : items;
  }, [compareFn, zodSchema, snapshot]);

  if (isLoading) return [[], true, undefined, undefined];
  if (error) return [[], false, error, undefined];

  return [docs, false, error, snapshot];
}
