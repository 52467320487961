import { useMyUserRecord } from "@stai/common";
import { FC, PropsWithChildren, useEffect } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

export const MyProjectGuard: FC<PropsWithChildren> = () => {
  const user = useMyUserRecord((state) => state.user);
  const { projectId } = useParams<{ projectId: string }>();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      projectId &&
      user &&
      user.managedProjectIds &&
      !user.managedProjectIds.includes(projectId)
    ) {
      console.log("The project does not belong to user");
      navigate(`/projects`);
    }
  }, [user]);

  return <Outlet />;
};
