import { SxProps, Theme } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ReactNode } from "react";
import { Control, Controller, FieldPath, FieldValues } from "react-hook-form";

interface Props<T extends FieldValues> {
  control: Control<T>;
  name: FieldPath<T>;
  label?: ReactNode;
  sx?: SxProps<Theme>;
  required?: boolean;
  disabled?: boolean;
}

export function ControlledDatePicker<T extends FieldValues>(props: Props<T>) {
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        ...(props.required && { required: "Required" }),
      }}
      render={({ field: { onChange, value, ref } }) => (
        <DatePicker
          label={props.label}
          inputRef={ref}
          sx={props.sx}
          disabled={props.disabled}
          value={value}
          onChange={onChange}
          onAccept={onChange}
        />
      )}
    />
  );
}
