import { getCollectionRef, getDataFromQuerySnapshot } from "@stai/common";
import { Project, ProjectsCollection } from "@stai/types";
import { onSnapshot, query, where } from "firebase/firestore";
import { create } from "zustand";

interface State {
  projects: Project[];
  isInitializing: boolean;
  subscribe: (projectIds: string[]) => void;
  unsubscribe?: () => void;
}

export const useMyProjects = create<State>((set) => ({
  projects: [],
  isInitializing: true,
  subscribe: (projectIds) => {
    if (!projectIds.length) {
      set({ projects: [], isInitializing: false });
      return;
    }

    const unsubscribeFirestore = onSnapshot(
      query(
        getCollectionRef(ProjectsCollection),
        where("id", "in", projectIds)
      ),
      (snapshot) => {
        if (snapshot.metadata.hasPendingWrites) return;

        const projects = getDataFromQuerySnapshot(Project, snapshot);

        set({ projects, isInitializing: false });
      },
      () => {
        set({ projects: [], isInitializing: false });
      }
    );

    set({
      unsubscribe: () => {
        set({ projects: [], isInitializing: true });
        unsubscribeFirestore();
      },
    });

    return () => {
      unsubscribeFirestore();
    };
  },
}));
