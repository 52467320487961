import { Box, Button } from "@mui/material";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { MAIN_PALETTE } from "../../Main/consts/MAIN_PALETTE";

function AddGuestButton({ to = "add-stay", text = "Add guest" }) {
  return (
    <Box sx={{ position: "relative", minHeight: "50px" }}>
      <Box
        sx={{
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          padding: 2,
          backgroundColor: MAIN_PALETTE.common.black,
          textAlign: "center",
        }}
      >
        <Button
          variant="contained"
          color="success"
          component={Link}
          to={to}
          size="large"
          sx={{ width: "100%", maxWidth: "400px", textTransform: "uppercase" }}
        >
          {text}
        </Button>
      </Box>
    </Box>
  );
}
AddGuestButton.defaultProps = {
  to: "add-stay",
  text: "Add guest",
};

// Define prop types
AddGuestButton.propTypes = {
  to: PropTypes.string,
  text: PropTypes.string,
};

export default AddGuestButton;
